<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1>Мониторинг информационных мероприятий
            <CznUserInfo :change-callback="behalUserChangeCallback"></CznUserInfo>
          </h1>

          <h2>Направления</h2>

          <v-expansion-panels>
            <div v-for="direction in config.directions" :key="direction.id" class="list monitoring">

              <div class="list-item-wrap">

                <v-expansion-panel
                    :key="'l1'+direction.id"
                >
                  <v-expansion-panel-header>

                    <div class="list-item">
                      <a class="list-item-name">
                        <button class="toggleSwitch">
                          <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                          </svg>
                          <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                          </svg>
                        </button>
                        <span>{{direction.name}}</span>
                      </a>
                      <v-progress-circular
                          :rotate="-90"
                          :size="50"
                          :value="getDirectionReadiness(direction.id)"
                          :width="6"
                          color="#0033A0"
                      >
                        {{ getDirectionReadiness(direction.id) }}
                      </v-progress-circular>
                        <div class="edit" @click.stop="$router.push('/infevents/edit/'+direction.id + (configId ? '/' + configId : ''))">
                          <v-icon color="#0033A0">mdi-note-edit-outline</v-icon>
                        </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <table>
                      <thead>
                      <tr>
                        <th>Мероприятие</th>
                        <th>Дата завершения</th>
                        <th>Охват</th>
                        <th>Дополнительная информация</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="action in direction.actions" :key="direction.id+'.'+action.id">
                        <td>{{action.name}}</td>
                        <td>{{ (t = getValue(direction.id, action.id)) ? formatDate(t.endDate) : '&mdash;'}}</td>
                        <td>{{ (t = getValue(direction.id, action.id)) ? t.scale : '&mdash;'}}</td>
                        <td>{{ (t = getValue(direction.id, action.id)) ? t.comment : '&mdash;'}}</td>
                      </tr>
                      <tr>
                        <td colspan="5">
                          <v-checkbox
                              label="Готовность к предоставлению материалов"
                              :readonly="!editable"
                              :input-value="getReadiness(direction.id)"
                              @change="submitReadiness(direction.id, $event)"
                          ></v-checkbox>
                        </td>
                      </tr>
                      </tbody>
                    </table>


                  </v-expansion-panel-content>
                </v-expansion-panel>
              </div>

            </div>

          </v-expansion-panels>

        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>

</template>

<style lang="scss">
@import "../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

import CznUserInfo from "./elements/CznUserInfo";

export default {
  name: 'InfeventsInfo',
  props: {},
  components: {CznUserInfo},
  data() {
    return {
      configId: null,
      config: null,
      values: null,
      readinessArray: null,
      apiLoaded: false,
      editable: false,
      errorText: null
    };
  },
  methods: {
    async loadConfig() {
      let req
      if (this.configId == null) {
        req = await this.$getApi("/infevents/getActiveConfig")
      } else {
        req = await this.$getApi("/infevents/getConfig", {id: this.configId})
      }
      if (req.ok) {
        this.config = req.payload
        this.config.directions = JSON.parse(this.config.directionsJson)
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },


    async loadInfeventsValues() {
      let req = await this.$getApi("/infevents/getStageValues",
          {userName: this.$behalfUser, configId: this.config.id})
      if (req.ok) {
        this.values = req.payload
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    async loadReadinessArray() {
      let req = await this.$getApi("/infevents/getDirectionStatuses",
          {userName: this.$behalfUser, configId: this.config.id})
      if (req.ok) {
        this.readinessArray = req.payload
        // console.log(this.readinessArray)
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    getDirectionReadiness(directionId) {
      return Math.round(this.getDirectionValuesPresent(directionId) / this.getDirectionStagesCount(directionId) * 100)
    },

    getDirectionStagesCount(directionId) {
      let count = 0
      for (let direction of this.config.directions) {
        if (direction.id === directionId) {
          count += direction.actions.length
        }
      }
      return count
    },

    getDirectionValuesPresent(directionId) {
      let count = 0
      for (let value of this.values) {
        if (value.directionId === directionId)
          count++
      }
      return count
    },

    getDirection(directionId) {
      for (let direction of this.config.directions) {
        if (directionId === direction.id)
          return direction
      }
      return null
    },

    getAction(directionId, actionId) {
      let direction = this.getDirection(directionId)
      if (direction == null)
        return null
      for (let action of direction.actions) {
        if (action.id === actionId)
          return action
      }
      return null
    },

    getReadiness(directionId) {
      for (let status of this.readinessArray) {
        if (status.directionId === directionId)
          return true
      }
      return false
    },

    formatDate(date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    },

    getValue(directionId, actionId){
      for(let value of this.values){
        if(value.directionId === directionId && value.actionId === actionId){
          return value
        }
      }
      return null
    },

    async submitReadiness(directionId, val) {
      if (this.apiFreeze) {
        return
      }
      this.apiFreeze = true
      this.editable = false
      let req = await this.$postFormDataApi("/infevents/saveDirectionStatus", {
        userName: this.$user.username,
        configId: this.config.id,
        directionId: directionId,
        ready: val
      })
      if (!req.ok) {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
      this.editable = true
      this.apiFreeze = false
    },

    async initializeData() {
      await this.loadConfig()
      let prms1 = this.loadInfeventsValues()
      let prms2 = this.loadReadinessArray()
      await prms1
      await prms2
      this.editable = (this.$user.role==='ROLE_CZN_MANAGER' || this.$user.role==='ROLE_CZN_TERRITORIAL') && (this.config.active)
    },

    async behalUserChangeCallback() {
      await this.initializeData()
    }
  },
  async beforeMount() {
    this.configId = this.$route.params.configId
    await this.initializeData()
    this.apiLoaded = true
  }
};
</script>
