<template>
  <span v-if="$user.role==='ROLE_CZN_MANAGER' || $user.role==='ROLE_CZN_TERRITORIAL'">{{$user.region.name}}, {{$user.name}}</span>
  <v-autocomplete v-else
                  v-model="behalfUser"
                  :items="$cznDepartmentsByRegionList"
                  item-text="name"
                  item-value="code"
                  return-object
                  style="width: 40%"
                  @change="onSelectChange"></v-autocomplete>
</template>

<script>
  export default {
    name: 'CznUserInfo',
    props: ['changeCallback'],

    data: () => ({
      behalfUser: null
    }),
    methods:{
      onSelectChange() {
        this.$setBehalfUser(this.behalfUser)
        this.changeCallback()
      }
    },
    beforeMount() {
      // this.behalfUser = this.$behalfUser
      this.behalfUser = this.$cznDepartmentsByRegionList[0]
    }

  }
</script>
